<template lang="pug">
  .vue-pouvoir-appert
    copie-pouvoir-en-cours(v-if="solved" names="appert")
    div(v-else)
      img.w-100.p-absolute.bg(src="@/assets/pouvoirs/appert/fond.jpg" srcset="@/assets/pouvoirs/appert/fond@2x.jpg 2x")
      menu-button
      h2.text-block.p-absolute
        div Sélectionnez
        div les bonnes conserves
      each idx in [1, 2, 3, 4, 5, 6, 7, 8, 9]
        img.boite(
          id="boite_0" + idx
          src="@/assets/pouvoirs/appert/boite_0" + idx + ".png"
          srcset="@/assets/pouvoirs/appert/boite_0" + idx + "@2x.png 2x"
          @click="select(" + idx + ")"
          :class="{ selected: isSelected(" + idx + ") }")
      #boite_01_info.boite-info(@click="select(1)" :class="{ selected: isSelected(1) }")
        div Maïs
        div Date : 09/09
        div N° Série : 057
      #boite_02_info.boite-info(@click="select(2)" :class="{ selected: isSelected(2) }")
        div Haricots verts
        div Date : 02/08
        div N° Série : 047
      #boite_03_info.boite-info(@click="select(3)" :class="{ selected: isSelected(3) }")
        div Maïs
        div Date : 14/11
        div N° Série : 034
      #boite_04_info.boite-info(@click="select(4)" :class="{ selected: isSelected(4) }")
        div Sauce tomate
        div Date : 26/03
        div N° Série : 098
      #boite_05_info.boite-info(@click="select(5)" :class="{ selected: isSelected(5) }")
        div Maïs
        div Date : 13/10
        div N° Série : 067
      #boite_06_info.boite-info(@click="select(6)" :class="{ selected: isSelected(6) }")
        div Sauce tomate
        div Date : 12/10
        div N° Série : 016
      #boite_07_info.boite-info(@click="select(7)" :class="{ selected: isSelected(7) }")
        div Haricots verts
        div Date : 02/10
        div N° Série : 122
      #boite_08_info.boite-info(@click="select(8)" :class="{ selected: isSelected(8) }")
        div Haricots verts
        div Date : 30/12
        div N° Série : 007
      #boite_09_info.boite-info(@click="select(9)" :class="{ selected: isSelected(9) }")
        div Soupe de tomate
        div Date : 19/10
        div N° Série : 074
</template>

<script>
import MenuButton from '@/components/MenuButton.vue'
import CopiePouvoirEnCours from '@/components/CopiePouvoirEnCours.vue'

const SOLUTION = '2-4-9'

export default {
  name: 'VuePouvoirAppert',

  components: {
    MenuButton, CopiePouvoirEnCours
  },

  computed: {
    value () {
      return this.selection.join('-')
    }
  },

  data () {
    return {
      selection: [],
      solved: false
    }
  },

  methods: {
    select (n) {
      this.$sound('touch')
      const p = this.selection.indexOf(n)
      if (p !== -1) {
        this.selection.splice(p, 1)
      } else {
        this.selection.push(n)
      }
      this.selection.sort((a, b) => parseInt(a) - parseInt(b))

      const solved = this.value === SOLUTION
      if (solved) {
        this.$sound('validate')
        setTimeout(() => { this.solved = true }, 1000)
      }
    },

    isSelected (n) {
      return this.selection.indexOf(n) !== -1
    }
  }
}
</script>

<style lang="less">
.vue-pouvoir-appert {
  h2 {
    width: 34%;
    left: 33%;
    top: 1%;
    font-size: 18pt;
    font-weight: bold;
    padding: 0.5em;
  }

  .boite-info {
    position: absolute;
    color: rgba(255,255,200,0.8);
    font-style: italic;
    font-weight: bold;
    text-transform: uppercase;
    font-family: Barlow;
    font-size: 40px;
    letter-spacing: -1px;

    &::after {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%,60px);
      content: " ";
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 30px;
      background: transparent;
      border: 3px solid rgba(255,255,200,0.8);
    }

    &.selected {
      color: black;
    }
    &.selected::after {
      background: black;
      border-color: white;
    }
  }
  #boite_01_info {
    top: 220px;
  }
  #boite_02_info, #boite_03_info {
    top: 240px;
  }
  #boite_01_info {
    left: 60px;
  }
  #boite_02_info {
    left: 910px;
  }
  #boite_03_info {
    left: 1380px;
  }

  #boite_04_info {
    top: 550px;
  }
  #boite_05_info, #boite_06_info {
    top: 540px;
  }
  #boite_04_info {
    left: 320px;
  }
  #boite_05_info {
    left: 710px;
  }
  #boite_06_info {
    left: 1590px;
  }

  #boite_07_info {
    top: 850px;
  }
  #boite_08_info, #boite_09_info {
    top: 840px;
  }
  #boite_07_info {
    left: 96px;
  }
  #boite_08_info {
    left: 950px;
  }
  #boite_09_info {
    left: 1290px;
  }

  img.boite {
    position: absolute;
    width: 175px;
    height: 238px;
  }
  img#boite_01, img#boite_02, img#boite_03 {
    top: 19.4%;
  }
  img#boite_01 {
    top: 17%;
    left: 16%;
  }
  img#boite_02 {
    left: 35%;
  }
  img#boite_03 {
    left: 85%;
  }

  img#boite_04 {
    left: 4%;
    top: 48%;
  }
  img#boite_05 {
    left: 50%;
    top: 47%;
  }
  img#boite_06 {
    left: 70%;
    top: 47%;
  }

  img#boite_07 {
    top: 75.7%;
  }
  img#boite_07 {
    left: 21%;
  }
  img#boite_08 {
    top: 75%;
    left: 38.5%;
  }
  img#boite_09 {
    top: 74%;
    left: 83%;
  }
}
</style>
